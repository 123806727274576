import {isPreviouslySavedQuoteEqualToCurrent} from 'Src/utilities/ExistingQuoteHelpers'
import {defaultFirstProgram, getCurrentProgram, validateProgram} from '../utilities/ProgramHelpers'
import {updateDeskingData} from '../apis/desking'

export const buildParams = (dealState, customer) => {
  const {defaultZipCode, defaultCreditScore, selectedRebateIds, creditScore} = dealState

  const {
    trade,
    customerData: {address, zipCode, cityCounty},
  } = customer

  const payloadCreditScore =
    creditScore.toString() !== defaultCreditScore.toString() ? creditScore.toString() : null
  const payloadZipCode = zipCode && zipCode !== defaultZipCode ? zipCode : null

  const params = {}

  if (payloadZipCode) {
    params.zipCode = payloadZipCode
  }

  if (payloadCreditScore) {
    params.creditScore = payloadCreditScore
  }

  if (selectedRebateIds.length) {
    params.selectedRebates = selectedRebateIds
  }

  if (cityCounty && cityCounty.id) {
    params.regionId = cityCounty.id.toString()
  }

  if (cityCounty && 'inCity' in cityCounty && cityCounty.inCity !== null) {
    params.inCity = Boolean(cityCounty.inCity)
  }

  if (cityCounty && 'city' in cityCounty && cityCounty.city !== null) {
    params.city = cityCounty.city
  }

  if (cityCounty && 'county' in cityCounty && cityCounty.county !== null) {
    params.county = cityCounty.county
  }

  if (address && 'id' in address && address.id !== null) {
    params.addressId = address.id.toString()
  }

  if (trade && trade.value) {
    params.tradeValue = trade.value.toString()
  }

  if (trade && trade.payoff) {
    params.tradePayoffValue = trade.payoff.toString()
  }

  return {...params}
}

export const loadDefaults = (response, dealDispatch) => {
  const {
    attributes: {
      creditScoreRange,
      defaults,
      dealTypes: {lease, finance},
      inputParams: {zipCode: inputZipCode},
      vehicle,
    },
  } = response?.data

  dealDispatch({
    type: 'update',
    payload: {
      ...response,
      dirty: false,
      retailDownPayment: finance?.defaults?.downPayment,
      minRetailDownPayment: finance?.defaults?.minDownPayment,
      maxRetailDownPayment: finance?.defaults?.maxDownPayment,
      retailDownPaymentIncrements: finance?.defaults?.downPaymentIncrements,
      leaseDownPayment: lease?.defaults?.downPayment,
      minLeaseDownPayment: lease?.defaults?.minDownPayment,
      maxLeaseDownPayment: lease?.defaults?.maxDownPayment,
      leaseDownPaymentIncrements: lease?.defaults?.downPaymentIncrements,
      leaseFixedDownPayment: lease?.defaults?.readOnlyDownPayment,
      retailTerm: finance?.defaults?.term,
      leaseTerm: lease?.defaults?.term,
      defaultCreditScore: defaults?.creditScore,
      creditScore: defaults?.creditScore.toString(),
      minCreditScore: creditScoreRange?.min,
      maxCreditScore: creditScoreRange?.max,
      zipCode: inputZipCode ?? defaults?.zipCode,
      defaultZipCode: defaults?.zipCode,
      selectedLeaseMileageOption: lease?.defaults?.annualMileage,
      vehicle,
      loaded: true,
    },
  })

  const state = {
    dealType: 'finance',
    retailDownPayment: finance?.defaults?.downPayment,
    retailTerm: finance?.defaults?.term,
  }

  // If no program with defualts we default to first program (only applicable to retail first load)
  if (!validateProgram(state, {}, response.data)) {
    defaultFirstProgram(state, dealDispatch, {}, response.data)
  }
}

export const loadNoPrograms = (response, dealDispatch) => {
  const {noProgramsCause, vehicle} = response?.data?.attributes
  dealDispatch({
    type: 'update',
    payload: {
      ...response,
      noProgramsCause,
      vehicle,
      loading: false,
      loaded: true,
    },
  })
}

export const revertToPreviousSelection = (prevValue, stateUpdates, dealDispatch) => {
  // Will not update state with new desking call response
  dealDispatch({
    type: 'update',
    payload: {
      ...prevValue,
      ...stateUpdates,
      displayNoProgramFoundMessage: true,
    },
  })
}

export const handleNoLeaseProgramsWhenAddingTrade = (response, dealDispatch) => {
  dealDispatch({
    type: 'update',
    payload: {
      ...response,
      displayTradeNoProgramFoundMessage: true,
      dealType: 'finance',
    },
  })
}

export const loadUpdates = (response, dealDispatch, prevValue, dealState, addedTrade = false) => {
  const {
    attributes: {
      creditScoreRange,
      dealTypes: {lease},
    },
  } = response?.data
  const stateUpdates = {loading: false, isUpdating: false, loaded: true}

  // No lease programs returned because of a non add trade call
  if (!addedTrade && dealState.dealType === 'lease' && Array.isArray(lease) && !lease.length) {
    revertToPreviousSelection(prevValue, stateUpdates, dealDispatch)

    return
  }

  // If no program found in response with current selections
  if (!validateProgram(dealState, {}, response.data)) {
    // Switch to first program by default and display user message
    if (!defaultFirstProgram(dealState, dealDispatch, {}, response.data)) {
      // If switching fails
      handleNoLeaseProgramsWhenAddingTrade(response, dealDispatch)

      return
    }

    dealDispatch({type: 'update', payload: {displaySwitchToFirstProgramMessage: true}})
  }

  dealDispatch({
    type: 'update',
    payload: {
      ...stateUpdates,
      ...response,
      minCreditScore: creditScoreRange?.min,
      maxCreditScore: creditScoreRange?.max,
    },
  })
}

export const resetDownPaymentState = (dispatch) => {
  if (!dispatch) {
    return
  }

  dispatch({
    type: 'update',
    payload: {
      disableDecreaseDownPayment: false,
      disableIncreaseDownPayment: false,
      displayDownPaymentMessage: false,
    },
  })
}

export const handleUpdateDeskingDataSuccess = (
  response,
  dealDispatch,
  useDefaults,
  prevValue,
  dealState,
  addedTrade = false
) => {
  const {data: responseData} = response

  resetDownPaymentState(dealDispatch)

  if (responseData?.data?.attributes?.noProgramsCause) {
    loadNoPrograms(responseData, dealDispatch)

    return false
  }

  if (useDefaults) {
    loadDefaults(responseData, dealDispatch)

    return true
  }

  loadUpdates(responseData, dealDispatch, prevValue, dealState, addedTrade)

  return true
}

export const handleUpdateDeskingDataFail = (error, dealDispatch) => {
  console.error(error)

  if (dealDispatch) {
    dealDispatch({
      type: 'update',
      payload: {
        ...{loadFailed: true},
      },
    })
  }

  return false
}

export const shouldUseDefaults = (isPriceLocked, dealState, resetDealDefaults = false) => {
  const {dirty, loaded} = dealState

  // Don't reset defaults if user has edited fields before logging in (with unlocked price)
  if (!isPriceLocked && dirty) {
    return false
  }

  // Special case where we arrive from a no programs found due to trade being too large.
  if (resetDealDefaults) {
    return true
  }

  return !loaded
}

export const createDeskingDataPayload = (data, dealState, customerState = {customerData: {}}) => {
  const {requestParams, useCache} = data

  return {
    data: {
      attributes: {
        ...buildParams(dealState, customerState),
        ...requestParams,
        useCache,
      },
    },
  }
}

export const retrieveDealData = (
  data,
  dealDispatch,
  api,
  dealState,
  customerState = {customerData: {}},
  resetDealDefaults = false,
  addedTrade = false
) => {
  const {vin, dealershipId, isPriceLocked, prevValue} = data
  const useDefaults = shouldUseDefaults(isPriceLocked, dealState, resetDealDefaults)
  const payload = createDeskingDataPayload(data, dealState, customerState)

  return updateDeskingData(api, dealershipId, payload, vin)
    .then((response) => {
      return handleUpdateDeskingDataSuccess(
        response,
        dealDispatch,
        useDefaults,
        prevValue,
        dealState,
        addedTrade
      )
    })
    .catch((error) => {
      return handleUpdateDeskingDataFail(error, dealDispatch)
    })
}

export const saveQuote = async (
  dealState,
  customerState,
  frameContext,
  customerDispatch,
  dealDispatch,
  getSelectedRatedProducts = () => []
) => {
  const {
    creditScore,
    dealType,
    minCreditScore,
    maxCreditScore,
    retailDownPayment,
    leaseDownPayment,
    leaseFixedDownPayment,
    retailTerm,
    leaseTerm,
    selectedLeaseMileageOption,
    isSavingQuote,
  } = dealState
  const {
    leadId,
    leadVehicles,
    customerData: {zipCode, cityCounty},
  } = customerState

  const {previouslySavedQuote} = customerState

  if (
    isPreviouslySavedQuoteEqualToCurrent(
      previouslySavedQuote,
      dealState,
      cityCounty,
      getSelectedRatedProducts
    )
  ) {
    return null
  }

  if (isSavingQuote) {
    return
  }

  dealDispatch({type: 'setSavingQuote', payload: true})

  const program = getCurrentProgram(dealState)

  if (!program) {
    return null
  }

  const {programId, appliedRebatesIds, rebatesTotal} = program
  const leadVehicle = leadVehicles?.find(({isCurrent}) => isCurrent === true)
  const leadVehicleIndex = leadVehicles?.findIndex(({isCurrent}) => isCurrent === true)
  const {a2zCustomerApi, dealership} = frameContext
  const mileage = dealType === 'lease' ? {mileage: selectedLeaseMileageOption} : {}
  const includeProductsWithoutPrices = true
  const body = {
    data: {
      attributes: {
        creditScore,
        minCreditScore,
        maxCreditScore,
        dealType,
        cashDown: dealType === 'finance' ? retailDownPayment : leaseDownPayment,
        leaseFixedDownPayment,
        inCity: !!cityCounty?.inCity,
        leadId,
        ...mileage,
        otherBanksColumnId: dealState?.data?.attributes?.defaults?.otherBanksColumnId ?? 0,
        products: getSelectedRatedProducts(dealType, includeProductsWithoutPrices)?.map(
          (product) => {
            return {
              miles: product.miles,
              rate_id: product.rate_id,
              product_id: product.product_id,
              price: product.price,
              term: product.term,
              uid: product.uid,
            }
          }
        ),
        programId,
        regionId: cityCounty?.id?.toString(),
        selectedRebates: appliedRebatesIds,
        rebatesTotal: programId === 0 ? rebatesTotal : 0,
        vehicleId: leadVehicle?.attributes?.vehicle_id,
        zipCode,
      },
    },
  }

  if (dealType === 'finance') {
    body.data.attributes.term = retailTerm
    body.data.attributes.rate = program?.rate
  }

  if (dealType === 'lease') {
    body.data.attributes.term = leaseTerm
  }

  const options = {
    headers: {'dealership-id': dealership?.id},
  }

  const result = await a2zCustomerApi
    .post(`/quote/store`, body, options)
    .then((response) => {
      const responseLeadVehicle = response.data?.data
      responseLeadVehicle.isCurrent = true

      if (response.data?.data?.id === leadVehicle?.id) {
        leadVehicles[leadVehicleIndex] = responseLeadVehicle
      } else {
        const alreadyALeadVehicleIndex = leadVehicles?.findIndex(
          ({id}) => id === responseLeadVehicle?.id
        )
        delete leadVehicle.isCurrent
        leadVehicles[leadVehicleIndex] = leadVehicle
        leadVehicles[
          alreadyALeadVehicleIndex >= 0 ? alreadyALeadVehicleIndex : leadVehicles.length
        ] = responseLeadVehicle
      }

      customerDispatch({type: 'setLeadVehicles', payload: leadVehicles})

      return responseLeadVehicle.id
    })
    .catch(() => false)
    .finally(() => {
      dealDispatch({type: 'setSavingQuote', payload: false})
    })

  return result
}

export const setDownPaymentButtons = (buttonState, dispatch, targetButton = 'both') => {
  if (!dispatch) {
    return
  }

  switch (targetButton) {
    case 'down':
      dispatch({
        type: 'update',
        payload: {
          disableDecreaseDownPayment: buttonState,
        },
      })

      return
    case 'up':
      dispatch({
        type: 'update',
        payload: {
          disableIncreaseDownPayment: buttonState,
        },
      })

      return
    default:
      dispatch({
        type: 'update',
        payload: {
          disableDecreaseDownPayment: buttonState,
          disableIncreaseDownPayment: buttonState,
        },
      })
  }
}
