import {retrieveDealData} from 'Src/actions/DealActions'
import {trans} from 'Src/utilities/Helpers'
import {pushToCrm} from 'Src/apis/leads'

export const getAndSetCityList = async (api, zipCode, registrationDispatch, registrationState) => {
  if (registrationState?.cityListZipCode === zipCode) {
    return registrationState.cityList
  }

  if (zipCode.length !== 5) {
    registrationDispatch({type: 'reset'})
    registrationDispatch({
      type: 'setCityListLoadingError',
      payload: trans('validation.invalid', {attribute: trans('zip_code')}),
    })
    registrationDispatch({type: 'setCityListLoading', payload: false})

    return false
  }

  const {
    data: {data: cityList},
  } = await api.get(`/cities/${zipCode}`)

  if (!cityList?.length) {
    registrationDispatch({
      type: 'reset',
    })
    registrationDispatch({
      type: 'setCityListLoadingError',
      payload: trans('city_not_found_zip', {zip: zipCode}),
    })

    return false
  }

  registrationDispatch({type: 'setCityList', payload: cityList})
  registrationDispatch({type: 'setCityListZipCode', payload: zipCode})
  registrationDispatch({type: 'setCityListLoading', payload: false})
  registrationDispatch({type: 'setCityListLoadingError', payload: false})

  return cityList
}

/**
 *
 * @param {null|number} customerId
 * @param {string} navigationPath
 * @param {function} navDispatch
 * @param {History<unknown>} history
 * @param {null|function} optionalFn
 * @param {null|function} bypassRegistrationFn
 * @param {boolean} runOptionalIfRegistered
 * @returns undefined
 */
export const navigateIfUserRegistered = (
  customerId,
  navigationPath,
  navDispatch,
  history,
  optionalFn = null,
  bypassRegistrationFn = null,
  runOptionalIfRegistered = true
) => {
  if (customerId || history.location.pathname !== '/') {
    if (optionalFn && runOptionalIfRegistered) {
      optionalFn()
    }

    if (bypassRegistrationFn) {
      bypassRegistrationFn()
    }

    if (navigationPath) {
      history.push(navigationPath)
    }

    return
  }

  navDispatch({
    type: 'navigateAfterRegistration',
    payload: {page: navigationPath},
  })

  history.push('/register')
}

export const getVehicleTypes = async (a2zApi) => {
  let vehicleTypesResponse

  try {
    vehicleTypesResponse = await a2zApi.get('/vehicles/types')
  } catch (error) {
    console.error('Could not fetch Vehicle Types.', error)
  }

  return vehicleTypesResponse?.data?.data || []
}

export const getVehicleTypeByName = async (a2zApi, vehicleTypeName) => {
  const vehicleTypes = await getVehicleTypes(a2zApi)

  return vehicleTypes.find((type) => type.attributes.name === vehicleTypeName.toLowerCase())
}

export const createLeadVehicle = async (
  leadId,
  vehicle,
  dealershipId,
  customerDispatch,
  a2zApi,
  a2zCustomerApi,
  isPriceLocked,
  customerLeadVehicles = null,
  paymentCalculations = null
) => {
  if (!leadId || !vehicle) {
    return
  }

  const vehicleTypeId = (await getVehicleTypeByName(a2zApi, vehicle.type))?.id

  if (!customerLeadVehicles) {
    const leadResponse = await a2zCustomerApi.get(
      `/customers/leads/${leadId}?include=leadVehicles,leadVehicles.vehicle,leadVehicles.paymentCalculation`
    )
    const includedLeadData = leadResponse.data.included
    const existingVehicles = includedLeadData.filter(({type}) => type === 'vehicles')
    const leadVehicles = includedLeadData.filter(({type}) => type === 'leadVehicles')

    paymentCalculations = includedLeadData.filter(({type}) => type === 'paymentCalculations')

    customerLeadVehicles = leadVehicles.map((leadVehicle) => {
      const existingVehicle = existingVehicles.find(
        ({id}) => id === leadVehicle.attributes?.vehicle_id?.toString()
      )
      leadVehicle.attributes.vehicle = {id: existingVehicle, ...existingVehicle.attributes}

      return leadVehicle
    })
  }

  const existingLeadVehicle = customerLeadVehicles?.find(
    (leadVehicle) => leadVehicle?.attributes?.vehicle?.vin === vehicle.vin
  )

  if (existingLeadVehicle) {
    await pushToCrm(a2zCustomerApi, existingLeadVehicle.id)
    existingLeadVehicle.isCurrent = true

    const paymentCalculation = paymentCalculations?.find(
      (calculation) =>
        existingLeadVehicle?.attributes?.payment_calculation_id.toString() ===
        calculation.id.toString()
    )
    existingLeadVehicle.paymentCalculation = paymentCalculation

    customerDispatch({type: 'setLeadVehicles', payload: customerLeadVehicles})

    if (existingLeadVehicle.attributes.vehicle.type === 'new' && !isPriceLocked) {
      customerDispatch({type: 'setExistingVehicle', payload: existingLeadVehicle})
    }

    if (
      existingLeadVehicle.attributes.vehicle.type === 'used' ||
      !existingLeadVehicle.attributes.vehicle.certified
    ) {
      customerDispatch({type: 'setExistingVehicle', payload: existingLeadVehicle})
    }

    return
  }

  const leadVehicleData = {
    data: {
      type: 'leadVehicles',
      attributes: {},
      relationships: {
        lead: {
          data: {
            type: 'leads',
            id: leadId,
          },
        },
        dealership: {
          data: {
            type: 'dealerships',
            id: dealershipId,
          },
        },
        vehicle: {
          data: {type: 'vehicles'},
        },
      },
    },
    included: [
      {
        type: 'vehicles',
        attributes: vehicle,
        relationships: {
          vehicleType: {
            data: {
              type: 'vehicleTypes',
              id: vehicleTypeId,
            },
          },
        },
      },
    ],
  }

  const leadVehicleCreateResponseData = await a2zCustomerApi
    .post(`/customers/leads/${leadId}/vehicles`, leadVehicleData)
    .then((response) => response.data)

  const newLeadVehicle = leadVehicleCreateResponseData?.data

  if (!newLeadVehicle?.id) {
    return
  }

  await pushToCrm(a2zCustomerApi, newLeadVehicle.id)
  newLeadVehicle.isCurrent = true
  customerLeadVehicles.push(newLeadVehicle)
  customerDispatch({type: 'setLeadVehicles', payload: customerLeadVehicles})
}

export const getLeadTradeAndDealData = async (
  leadId,
  vehicle,
  dealershipId,
  customerData,
  customerDispatch,
  dealDispatch,
  dealState,
  api,
  customerApi,
  isPriceLocked,
  useCache,
  isSaveDealEnabled
) => {
  const leadResponseData = await customerApi
    .get(
      `/customers/leads/${leadId}?include=leadVehicles,leadVehicles.vehicle,leadVehicles.paymentCalculation,trades,trades.vehicle`
    )
    .then((response) => response.data)

  if (leadResponseData.data[0].attributes?.hasActiveCreditApp) {
    customerDispatch({type: 'setHasActiveCreditApp', payload: true})
  }

  const leadVehicles = leadResponseData.included.filter(({type}) => type === 'leadVehicles')
  const vehicles = leadResponseData.included.filter(({type}) => type === 'vehicles')
  const vehiclesById = vehicles.reduce(
    (previousValue, vehicleRecord) => ({...previousValue, [vehicleRecord.id]: vehicleRecord}),
    {}
  )

  const paymentCalculations = leadResponseData.included.filter(
    ({type}) => type === 'paymentCalculations'
  )

  const existingLeadVehicles = leadVehicles.map((leadVehicle) => {
    const existingVehicle = vehiclesById[leadVehicle.attributes.vehicle_id]

    leadVehicle.attributes.vehicle = {id: existingVehicle.id, ...existingVehicle.attributes}
    delete leadVehicle.relationships

    return leadVehicle
  })

  if (isSaveDealEnabled) {
    const previouslySavedLeadVehicle = getPreviouslySavedLeadVehicleQuote(
      vehicle.vin,
      leadVehicles,
      paymentCalculations
    )

    await customerDispatch({type: 'setPreviouslySavedQuote', payload: previouslySavedLeadVehicle})
    await customerDispatch({type: 'setShowPreviousDealModal', payload: true})
  }

  let tradePayload = null
  const trade = leadResponseData.included.find(({type}) => type === 'trades')

  if (trade) {
    const tradeVehicle = leadResponseData.included.find(
      ({type, id}) => type === 'vehicles' && id === trade.attributes?.vehicle_id?.toString()
    )
    tradePayload = {
      id: trade.id,
      ...trade.attributes,
      vehicle: {id: tradeVehicle?.id, ...tradeVehicle?.attributes},
    }

    customerDispatch({type: 'setTrade', payload: tradePayload})
  }

  customerDispatch({type: 'setLeadVehicles', payload: existingLeadVehicles})

  await createLeadVehicle(
    leadId,
    vehicle,
    dealershipId,
    customerDispatch,
    api,
    customerApi,
    isPriceLocked,
    existingLeadVehicles,
    paymentCalculations
  )

  if (vehicle?.id) {
    await retrieveDealData(
      {vin: vehicle.vin, dealershipId, isPriceLocked, useCache},
      dealDispatch,
      api,
      dealState,
      {...customerData, trade: tradePayload}
    )
  }

  return {leadVehicles: existingLeadVehicles}
}

const createCalculationMap = (calculations) => {
  return new Map(calculations.map((calculation) => [calculation.id, calculation.attributes]))
}

const getVehicleCalculations = (paymentCalculations, vehicleVin) => {
  const calculations = paymentCalculations.filter((calculation) => {
    const {
      attributes: {
        deal_presenter: {vin: calculationVin},
      },
    } = calculation

    return calculationVin === vehicleVin
  })

  return createCalculationMap(calculations)
}

const findMostRecentVehicleQuote = (leadVehicles, vehicleVin, calculationsMap) => {
  return leadVehicles.reduce((mostRecent, current) => {
    const {attributes: leadVehicleData} = current
    const leadCalculationId = leadVehicleData.payment_calculation_id?.toString()
    const leadVehicleVin = leadVehicleData.vehicle?.vin

    if (!leadCalculationId || vehicleVin !== leadVehicleVin) {
      return mostRecent
    }

    if (
      (mostRecent && new Date(leadVehicleData.created_at) < new Date(mostRecent.created_at)) ||
      !calculationsMap.has(leadCalculationId)
    ) {
      return mostRecent
    }

    current.calculation = calculationsMap.get(leadCalculationId)

    return current
  }, null)
}

const getPreviouslySavedLeadVehicleQuote = (vehicleVin, leadVehicles, paymentCalculations) => {
  const calculationsMap = getVehicleCalculations(paymentCalculations, vehicleVin)

  return findMostRecentVehicleQuote(leadVehicles, vehicleVin, calculationsMap)
}
