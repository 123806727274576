import {Dispatch, SetStateAction, ReducerAction, Reducer, useEffect, useState} from 'react'
import axios, {AxiosInstance} from 'axios'
import {useHistory, RouteComponentProps} from 'react-router'
import {displayPhoneNumber} from './Helpers'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {LOADING_STATE} from 'Src/reducers/NavReducer'
import useConfig from 'Src/utilities/useConfig'
import {transString} from 'Src/utilities/Helpers'

export interface IMessage {
  title: string
  description: string
  footer?: string
  type?: 'error' | 'success'
  onClose?(): void
}

interface IError {
  code: string
  response: {
    status: number
    data: {
      errors: IErrorObj[]
    }
    headers: Record<string, string>
    config?: {
      url: string
    }
  }
}

interface IErrorObj {
  code: string
  title: string
  detail: string
  meta?: {
    stack: string
  }
}

interface IConfig {
  baseURL?: string
  withCredentials?: boolean
}

interface IDealership {
  id: number
  phone: string
}

export type THistory = RouteComponentProps['history'] & {
  entries?: Location[] | undefined
}

export const buildInstance = (
  config: IConfig = {},
  {
    clientUuid,
    apiToken,
    dealership,
  }: {clientUuid: string; apiToken: string; dealership: IDealership},
  history: THistory,
  navDispatch: Dispatch<ReducerAction<Reducer<string, {type: string; payload: string}>>>,
  setMessages: Dispatch<SetStateAction<IMessage[]>>
) => {
  const instance = axios.create(config)

  instance.defaults.headers['Accept'] = 'application/vnd.api+json'
  instance.defaults.headers['Content-Type'] = 'application/vnd.api+json'
  instance.defaults.headers['client-uuid'] = clientUuid
  instance.defaults.headers['dealership-id'] = dealership.id
  instance.defaults.headers['Authorization'] = `Bearer ${apiToken}`

  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const notice = response?.data?.data?.attributes?.notice

      if (notice) {
        setMessages([
          {
            description: notice.detail,
            title: notice.title ?? '',
            type: notice.type,
          },
        ])
      }

      return response
    },
    (error: IError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (error.code === 'ECONNABORTED') {
        let errorDescription = 'Our Calculation engine is temporarily unavailable.'

        if (dealership && dealership.phone) {
          errorDescription += ` Please call us at ${displayPhoneNumber(
            dealership.phone
          )} for personalized assistance, or try again later.`
        } else {
          errorDescription += ' Please try again later.'
        }

        setMessages([
          {
            title: 'Calculation',
            description: errorDescription,
            type: 'error',
          },
        ])
      }

      if (
        typeof error.response !== 'undefined' &&
        error.response.headers['x-exception-version'] === 'jsonapi-v1'
      ) {
        // Display toastr message
        if (error.response?.config?.url.includes('cities') && error.response.status === 500) {
          setMessages([
            {
              title: 'Calculation',
              description: transString('customer_verification.zip_code_error'),
              type: 'error',
            },
          ])
        } else {
          error.response.data.errors.forEach((errorObj) => {
            if (errorObj?.meta?.stack) {
              console.error(errorObj.meta.stack)
            }

            let errorDescription = `${errorObj.detail}`

            if (dealership && dealership.phone) {
              errorDescription += ` Please call us at ${displayPhoneNumber(
                dealership.phone
              )} for personalized assistance, or try again later.`
            } else {
              errorDescription += ' Try again later.'
            }

            errorDescription += ` <strong>Code: ${errorObj.code}</strong>`

            if (error.response.config?.url.includes('cities')) {
              errorDescription = transString('customer_verification.zip_code_error')
            }

            // Display toastr message
            setMessages([
              {
                title: errorObj.title,
                description: errorDescription,
                type: 'error',
              },
            ])
          })
        }
      }

      if (error?.response?.status === 503) {
        const errorMessage = 'Our Calculation Engine is temporarily unavailable.'
        history.push(`/error?message=${errorMessage}`)
        navDispatch({type: 'setLoading', payload: LOADING_STATE.FAILED})
      }

      return Promise.reject(error)
    }
  )

  return instance
}

export const useAxios = (instanceName = 'dealership-token') => {
  const [apiInstance, setApiInstance] = useState<{instance: AxiosInstance} | null>(null)
  const [isApiReady, setIsApiReady] = useState(false)
  const {client, api, dealership} = useConfig()
  const [messages, setMessages] = useState<IMessage[]>([])
  const history: RouteComponentProps['history'] = useHistory()
  const navDispatch = useNavDispatchContext()

  useEffect(() => {
    if (!api?.url || !api?.token || !client?.uuid || !dealership) {
      return
    }

    let config = {}

    // temporary until we swap JWT with Sanctum
    if (instanceName === 'customer-token') {
      config = {
        baseURL: `${api.url}/digital-retail`,
        withCredentials: true,
      }
    }

    if (instanceName === 'dealership-token') {
      config = {baseURL: api.url, withCredentials: true}
    }

    const instance = buildInstance(
      config,
      {clientUuid: client.uuid, apiToken: api.token, dealership},
      history,
      navDispatch,
      setMessages
    )

    setApiInstance({instance})
    setIsApiReady(true)
  }, [api?.url, client?.uuid, api?.token, dealership])

  return [apiInstance?.instance, isApiReady, messages, setMessages]
}
