import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import {useNavDispatchContext, useNavStateContext} from '../contexts/NavContext'
import useEventListener from './useEventListener'
import {TRANSITION_DURATION} from '../components/SlideOut'

export const WINDOW_IFRAME_OPEN_MESSAGE = 'digitalretail-iframe-open'
export const WINDOW_IFRAME_CLOSE_MESSAGE = 'digitalretail-iframe-close'

const useSlideOutState = () => {
  const {showSlideOut} = useNavStateContext()
  const navDispatch = useNavDispatchContext()
  const [isOpen, setIsOpen] = useState(true)
  const location = useLocation()
  const history = useHistory()

  // Opens if embed script sends message
  useEventListener('message', (event: MessageEvent) => {
    if (event.data === WINDOW_IFRAME_OPEN_MESSAGE) {
      navDispatch({type: 'openSlideOut'})
      setIsOpen(true)
    }
  })

  // Opens or closes based on nav state
  useEffect(() => {
    if (location.pathname === '/register' && document.cookie.indexOf('pseudo-auth-token=') !== -1) {
      history.push('/')
    }

    let transitionTimeout: ReturnType<typeof setTimeout> | undefined

    setIsOpen(showSlideOut)

    if (!showSlideOut) {
      transitionTimeout = setTimeout(() => {
        window.parent.postMessage(WINDOW_IFRAME_CLOSE_MESSAGE, '*')
      }, TRANSITION_DURATION)
    }

    return () => {
      clearTimeout(transitionTimeout)
    }
  }, [showSlideOut])

  return {isOpen}
}

export default useSlideOutState
